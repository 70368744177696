@media (max-width: 575px) {
    .auth-footer .footer-options {
        display: block;
        text-align: center;
    }

    .auth-footer .footer-options .footer-links {
        margin-top: 10px;
        justify-content: space-between;

        .link-item {
            padding: 0px;
            font-weight: 500;
        }
    }

    .verification-body .otp-input .otp-control input {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    .mobile-menu {
        .mobile-menu-header {
            border-bottom: 1px solid #DBDFEA;

            img {
                height: 50px;
                width: auto;
            }
        }

        .mobile-menu-body {
            .navigation-options {
                .navigation-item {
                    font-size: 16px;
                    font-weight: 600;
                    text-decoration: none;
                    color: #323232;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .dashboard-header .navigation .left-navigation .header-logo {
        display: none;
    }

    .resp-start {
        justify-content: flex-start !important;
        margin-top: 10px;
    }

    .activity .activities-list .activities-list-body .activity-card {
        margin-top: 10px;
    }

    .rewards .users-table .users-list .users-list-header {
        display: block;
    }

    .rewards .users-table .users-list .users-list-header .right-header {
        margin-top: 10px;
    }

    .rewards .users-table .users-list .users-list-header .left-header {
        width: 100%;
    }

    .rewards .users-table .users-list .users-list-header .left-header .react-select {
        width: 100%;
    }

    .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell .user-detail .avatar {
        overflow: auto;
    }

    .campaigns .campaigns-list .campaigns-list-body .activity-card {
        margin-top: 10px;
    }

    .segments .campaigns-list .campaigns-list-body .activity-card {
        margin-top: 10px;
    }

    .dashboard .new-users {
        margin-top: 15px;
    }

    .dashboard .create-report-card  {
        background-image: none;
        margin-top: 30px;
    }

    .dashboard .social-network {
        margin-top: 20px;
    }

    .dashboard .activities-card {
        margin-top: 35px;
    }

    .dashboard .transaction-card {
        margin-top: 15px;
    }
    
    .main {
        min-height: auto !important;
    }

    .auth-footer {
        margin-top: 30px;
    }

    .table-cardbody {
        overflow: scroll;
    }

    .organization-settings .settings-tabs .settings-tabs-cardbody .table-card .table-cardbody .pagination-footer {
        display: block;
    }

    .pagination-btn-white {
        justify-content: center;
        margin-top: 5px;
    }

    .organization-settings .settings-tabs .settings-tabs-cardbody .table-card .table-cardbody .pagination-footer .card-footer-left {
        text-align: center;
    }

    .organization-settings .settings-tabs .settings-tabs-cardbody .table-card .table-cardbody .table .table-head th {
        white-space: nowrap;
    }

    .resp-padding {
        padding-left: 20px;
    }

    .resp-width {
        .role-size {
            margin-left: 20px;
        }
    }

    .organization-settings .settings-tabs .settings-tabs-cardbody .table-card .table-cardbody .table .table-body tr .member-details {
        margin-right: 10px;
    }

    .organization-settings .settings-tabs .settings-tabs-cardbody .table-card {
        margin-top: 15px;
    }

    .organization-settings .settings-tabs .settings-tabs-cardbody .left-content .content-para {
        font-size: 13px;
    }

    .organization-settings .settings-tabs .settings-tabs-cardbody .role-card {
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .organization-settings .settings-tabs .settings-tabs-cardbody .table-card .table-cardbody .table .table-body tr td {
        white-space: nowrap;
    }

    .dashboard-header .navigation .left-navigation .logo-box {
        display: none;
    }

    .agent-details {
        .agent-details-phone {
            display: block !important;
        }
    }

    .add-agent .agents-list .list-card .list-cardbody .agent-card .agent-details .dre-number {
        margin-left: 0px !important;
        margin-top: 5px !important;
    }

    .add-agent .agents-list .list-card .list-cardbody .agent-card .agent-details .telephone {
        margin-top: 5px !important;
    }

    .add-agent .agents-list .list-card .list-cardbody .agent-card .agent-details .engagement-btns {
        display: block !important;
    }
    
    .add-agent .agents-list .list-card .list-cardbody .agent-card {
        white-space: normal !important;
        margin-bottom: 15px !important;
    }

    .add-agent .agents-list .list-card .list-cardbody .agent-card .agent-details .contact-btn {
        margin-top: 5px !important;
    }

    .add-agent .agents-list .list-card .list-cardbody .agent-card .agent-details {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    
    .add-agent .agents-list .list-card .list-cardbody .agent-card img {
        border-radius: 5px;
    }

    .add-agent .agents-list .list-card .list-cardheader {
        display: block !important;
    }

    .add-agent .agents-list .list-card .list-cardheader .left-header {
        width: 100% !important;
        margin-bottom: 10px !important;
    }

    .add-agent .agents-list .list-card .list-cardheader .left-header .react-select {
        width: 100%;
    }

    .users .users-table .users-list .users-list-header {
        display: block !important;
    }

    .users .users-table .users-list .users-list-header .left-header {
        width: 100% !important;
        margin-bottom: 10px !important;
    }

    .users .users-table .users-list .users-list-header .left-header .react-select {
        width: 100%;
    }

    .blogs .blogs-list .list-card .list-cardheader {
        display: block !important;
    }

    .blogs .blogs-list .list-card .list-cardheader .left-header {
        width: 100% !important;
        margin-bottom: 10px !important;
    }

    .blogs .blogs-list .list-card .list-cardheader .left-header .react-select {
        width: 100% !important;
    }
}