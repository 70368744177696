.campaigns {
    .bg-blue-banner {
        background-color: #323232;
        height: 250px;
        width: 100%;
        position: absolute;
        z-index: -1;
    }

    .page-head {
        padding-top: 40px;
        padding-bottom: 40px;

        h1 {
            font-size: 30px;
            font-weight: 700;
            color: #FFF;
        }

        p {
            margin-bottom: 0px;
            color: #343434;
            font-weight: 600;
        }

        .import-btn {
            display: flex;
            align-items: center;
            padding: 8px 25px;
            background-color: #FFF;
            font-size: 14px;
            color: #323232;
            font-weight: 600;
            border: 0px solid #DBDFEA;

            .btn-icon {
                margin-right: 10px;
            }
        }

        .add-btn {
            background-color: #FFF;
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0px solid transparent;
        }
    }

    .campaigns-list {
        .campaigns-list-body {
            .activity-card {
                border: 1px solid #DBDFEA !important;

                .activity-cardbody {
                    h5 {
                        margin-bottom: 5px;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 14px;
                        color: grey;
                        line-height: 20px;
                    }

                    .active-badge {
                        background-color: rgba($color: #28C76F, $alpha: 0.2) !important;
                        color: #28C76F !important;
                        padding: 5px 15px;
                    }

                    .expired-badge {
                        background-color: rgba($color: #EA5455, $alpha: 0.2) !important;
                        color: #EA5455 !important;
                        padding: 5px 15px;
                    }

                    .campaign-users {
                        span {
                            font-weight: 500;
                            font-size: 12px;
                            color: grey;
                        }

                        .points-assigned {
                            margin-left: 50px;

                            h5 {
                                margin-top: 5px;
                            }
                        }

                        .user-avatar-list {
                            display: flex;
                            align-items: center;
                            margin-top: 5px;

                            .avatar {
                                height: 30px;
                                width: 30px;
                                background-color: #343434;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #FFF;
                                font-size: 12px;
                                border: 1px solid #FFF;
                            }

                            .avatar-l {
                                height: 30px;
                                width: 30px;
                                background-color: #343434;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #FFF;
                                font-size: 12px;
                                margin-left: -10px;
                                border: 1px solid #FFF;
                            }

                            p {
                                margin-bottom: 0px;
                                font-size: 13px;
                                font-weight: 600;
                                color: grey;
                                margin-left: 5px;
                            }
                        }
                    }

                    .activities-list {
                        margin-top: 10px;

                        span {
                            font-weight: 500;
                            font-size: 12px;
                            color: grey;
                        }

                        .list-selected {
                            margin-top: 5px;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;

                            .list-item {
                                background-color: rgba($color: #A8AAAE, $alpha: 0.2);
                                font-size: 13px;
                                padding: 5px 10px;
                                border-radius: 5px;
                                font-weight: 600;
                                margin-right: 10px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }

                .activity-cardfooter {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #FFF;
                    border-top: 1px solid #DBDFEA;

                    .date-range {
                        span {
                            font-size: 12px;
                            color: grey;
                            font-weight: 500;
                        }

                        p {
                            font-size: 14px;
                            margin-bottom: 0px;
                            font-weight: 600;
                            color: grey;
                        }
                    }

                    .card-action {
                        .dropdown-toggle {
                            background-color: #FFF;
                            border-radius: 50%;
                            padding: 6px 10px;
                            border-color: #FFF;
                        }

                        .dropdown-toggle:hover {
                            background-color: rgba($color: #323232, $alpha: 0.1);
                        }

                        .dropdown-toggle::after {
                            display: none;
                        }

                        .card-action-menu {
                            .menu-item {
                                font-size: 13px;
                                color: #323232;
                                padding-top: 10px;
                                padding-bottom: 10px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}

.react-select {
    width: 100%;

    .action-select__control {
        font-size: 14px;
        border: 1px solid #DBDFEA;

        .action-select__indicator-separator {
            display: none;
        }

        .action-select__placeholder {
            font-size: 13px;
        }
    }

    .action-select__menu {
        .action-select__menu-list {
            .action-select__option {
                font-size: 13px;
                font-weight: 400;
                color: #323232;
                background-color: #FFF;
            }
        }
    }
}