.organization-settings {
    .bg-blue-banner {
        background-color: #323232;
        height: 250px;
        width: 100%;
        position: absolute;
        z-index: -1;
    }

    .page-head {
        padding-top: 40px;
        padding-bottom: 40px;

        h1 {
            font-size: 30px;
            font-weight: 700;
            color: #FFF;
        }

        p {
            margin-bottom: 0px;
            color: #343434;
            font-weight: 600;
        }

        .resp-start {
            justify-content: flex-end;
        }

        .import-btn {
            display: flex;
            align-items: center;
            padding: 8px 25px;
            background-color: #FFF;
            font-size: 14px;
            color: #323232;
            font-weight: 600;
            border: 0px solid #DBDFEA;

            .btn-icon {
                margin-right: 10px;
            }
        }

        .add-btn {
            background-color: #FFF;
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0px solid transparent;
        }
    }

    .settings-card {
        border: 0px solid transparent;
        .settings-card-body {
            padding: 0px;

            .organization-form {
                .form-group {
                    label {
                        font-size: 13px;
                        font-weight: 600;
                        margin-bottom: 5px;
                    }
        
                    .upload-logo {
                        display: flex;
                        align-items: center;
        
                        .logo {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 60px;
                            width: 60px;
                            border-radius: 5px;
                            border: 1px solid #E5E5E5;
                            padding: 5px;
        
                            p {
                                color: #FFF;
                                font-weight: 700;
                                margin-bottom: 0px;
                            }
        
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
        
                        .logo-input {
                            margin-left: 10px;
                            font-size: 12px;
                            padding: 5px;
                            width: 300px;
                        }
                    }
                }
            }
        }
    }

    .activities-list {
        border: 0px solid transparent;

        .activities-list-body {
            padding: 0px;
            .activity-card {
                border: 1px solid #DBDFEA !important;

                .activity-cardbody {
                    h5 {
                        margin-bottom: 5px;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 14px;
                        color: grey;
                        line-height: 20px;
                    }

                    .active-badge {
                        background-color: rgba($color: #28C76F, $alpha: 0.2) !important;
                        color: #28C76F !important;
                        padding: 5px 15px;
                    }

                    .expired-badge {
                        background-color: rgba($color: #EA5455, $alpha: 0.2) !important;
                        color: #EA5455 !important;
                        padding: 5px 15px;
                    }
                }

                .activity-cardfooter {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #FFF;
                    border-top: 1px solid #DBDFEA;

                    .date-range {
                        span {
                            font-size: 12px;
                            color: grey;
                            font-weight: 500;
                        }

                        p {
                            font-size: 14px;
                            margin-bottom: 0px;
                            font-weight: 600;
                            color: grey;
                        }
                    }

                    .card-action {
                        .dropdown-toggle {
                            background-color: #FFF;
                            border-radius: 50%;
                            padding: 6px 10px;
                            border-color: #FFF;
                        }

                        .dropdown-toggle:hover {
                            background-color: rgba($color: #323232, $alpha: 0.1);
                        }

                        .dropdown-toggle::after {
                            display: none;
                        }

                        .card-action-menu {
                            .menu-item {
                                font-size: 13px;
                                color: #323232;
                                padding-top: 10px;
                                padding-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .settings-tabs {
        .settings-tabs-cardheader {
            background-color: #FFF;

            .card-header-pills {
                .nav-link.active {
                    font-size: 14px;
                    font-weight: 600;
                    background-color: #343434;
                    color: #FFF;
                }

                .nav-link {
                    color: grey;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }

        .settings-tabs-cardbody {
            padding: 20px;
            .left-content {
                .content-head {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                .content-para {
                    font-size: 14px;
                    color: grey;
                    font-weight: 400;
                    margin-bottom: 0px;
                }

                .invite-btn {
                    font-size: 14px;
                    font-weight: 600;
                    background-color: #343434;
                    margin-top: 15px;
                    border-color: #343434;
                }
            }

            .table-card {
                background-color: #F3F5F7;
                border: 0px solid transparent;

                .table-cardbody {
                    .table {
                        width: 100%;

                        .table-head {
                            th {
                                color: #afafaf;
                                font-size: 11px;
                                font-weight: 500;
                            }
                        }

                        .table-body {
                            tr {
                                border-bottom: 1px solid #e5e5e5;
                                vertical-align: middle;

                                td {
                                    color: #636363;
                                    font-weight: 500;
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                    font-size: 13px;
                                    padding-left: 0px;
                                }

                                .member-details {
                                    display: flex;
                                    align-items: center;

                                    .member-avatar {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        height: 35px;
                                        width: 35px;
                                        background-color: #323232;
                                        border-radius: 50%;

                                        p {
                                            margin-bottom: 0px;
                                            color: #fff;
                                        }
                                    }

                                    .member-info {
                                        margin-left: 10px;

                                        .name {
                                            color: #323232 !important;
                                            font-size: 14px !important;
                                            font-weight: 600;
                                            text-transform: capitalize;
                                            margin-bottom: 0px;
                                        }

                                        .email {
                                            color: #636363;
                                            font-weight: 400;
                                            font-size: 12px;
                                            margin-bottom: 0px;
                                        }
                                    }
                                }

                                .role-size {
                                    font-size: 12px;
                                    padding: 5px 10px;
                                    font-weight: 500;
                                    text-transform: capitalize;
                                    background-color: #343434 !important;
                                }

                                .role-select__control {
                                    border: 0px solid transparent;
                                    background-color: #323232;

                                    .role-select__single-value {
                                        color: #fff;
                                    }

                                    .role-select__indicator-separator {
                                        display: none;
                                    }
                                }

                                .delete-member-btn {
                                    background-color: #F3F5F7;
                                    border-radius: 50%;
                                    padding: 5px 10px;
                                    border-color: transparent;
                                }

                                .delete-member-btn:hover {
                                    background-color: rgba($color: #000, $alpha: 0.1);
                                }
                            }

                            .resend-invitation-btn {
                                background-color: #323232;
                                color: #fff;
                                border-color: #000;
                                font-size: 14px;
                                font-weight: 600;
                            }

                            .revoke-invitation-btn {
                                background-color: #f3f5f7;
                                border: 1px solid #323232;
                                margin-left: 10px;
                                color: #000;
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                    }

                    .pagination-footer {
                        background-color: #F3F5F7;
                        padding: 0px;
                        border-top: 0px solid transparent;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .card-footer-left {
                            span {
                                font-size: 13px;
                                font-weight: 600;
                            }
                        }

                        .card-footer-right {
                            .pagination {
                                margin-bottom: 0px;

                                .page-item {
                                    .page-link {
                                        color: #6C757D;
                                        font-size: 13px;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .role-card {
                background-color: #F3F5F7;
                border: 0px solid transparent;
                margin-bottom: 15px;

                .role-cardbody {
                    padding: 10px 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h5 {
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 0px;
                        padding-right: 10px;
                        word-wrap: break-word;
                    }

                    .delete-role {
                        height: 30px;
                        width: 30px;
                        background-color: rgba($color: #000, $alpha: 0.1);
                        border: 0px solid transparent;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0px;
                    }
                }
            }

            .checkbox-tick {
                label  {
                    justify-content: center !important;
                }
            }

            .new-checkbox-control {
                height: 16px !important;
                width: 16px !important;
                border-color: #CCCCCC !important;
                border-radius: 3px !important;
            }

            .modules-card {
                margin-bottom: 10px;

                .modules-cardheader {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #FFF;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    h5 {
                        font-size: 14px;
                        margin-bottom: 0px;
                        font-weight: 600;
                    }

                    .methods-number {
                        background-color: #F2FAF5;
                        font-size: 12px;
                        font-weight: 600;
                        border-radius: 20px;
                        border: 1px solid #059450;
                        padding: 3px 15px;
                    }
                }

                .modules-cardbody {
                    h5 {
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 0px;
                    }

                    p {
                        font-size: 13px;
                        line-height: 18px;
                        font-weight: 400;
                        margin-bottom: 0px;
                    }

                }
            }
        }
    }
}

.invite-modal-header {
    h5 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0px;
    }
}

.invite-modal-body {
    label {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .options-select__control {
        border: 1px solid #DBDFEA;
        font-size: 14px;

        .options-select__placeholder {
            font-size: 13px;
        }

        .options-select__indicator-separator {
            display: none;
        }
    }

    .options-select__menu {
        .options-select__option {
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.invite-modal-footer {
    .cancel-btn {
        background-color: #FFF;
        font-size: 14px;
        color: #000;
        font-weight: 600;
        border-color: #FFF;
    }

    .invite-btn {
        background-color: #343434;
        font-size: 14px;
        font-weight: 600;
        border-color: #343434;
    }
}

.new-role-modal-header {
    h5 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0px;

        span {
            color: red;
            font-size: 14px;
        }
    }
}

.new-role-modal-body {
    label {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 5px;
    }
}

.new-role-modal-footer {
    .cancel-btn {
        background-color: #FFF;
        font-size: 14px;
        color: #000;
        font-weight: 600;
        border-color: #FFF;
    }

    .invite-btn {
        background-color: #343434;
        font-size: 14px;
        font-weight: 600;
        border-color: #343434;
    }
}